import { useEffect } from 'react'
import { useWallet as useWalletLib } from 'use-wallet'
import { shortenAddress } from '@usedapp/core'
import usePreferences from 'src/hooks/usePreferences'
// import { formatEther } from '@ethersproject/units'
// import { networkId } from 'src/dapp'
// import { useEthers, useEtherBalance, useTokenBalance } from '@usedapp/core'
// import Gnomies from 'contract/build/contracts/Gnomies.json'
// import useChain from 'src/hooks/useChain'
// export { ConnectionRejectedError } from 'use-wallet'

const useWallet = () => {
  //   // const { active, deactivate, ...rest } = useEthers()
  //   // const { provider, network, chainId, isMetaMask } = useChain()
  const wallet = useWalletLib({
    pollBalanceInterval: 60 * 1000,
    pollBlockNumberInterval: 60 * 1000,
  })
  // const networkData = Gnomies.networks[networkId(chainId)] || {}
  // const etherBalance = useEtherBalance(wallet.account)
  // const tokenBalance = useTokenBalance(account, networkData.address)

  const {
    lastWalletConnection,
    setLastWalletConnection,
    shouldReconnect,
    setShouldReconnect,
  } = usePreferences()

  const disconnect = () => {
    setLastWalletConnection(null)
    setShouldReconnect(false)
    wallet.reset()
  }

  // remember to reconnect wallet next time page loads
  useEffect(() => {
    if (wallet.account && !shouldReconnect) {
      setShouldReconnect(true)
      setLastWalletConnection(wallet.connector)
    }
  }, [wallet.account, wallet.connector, lastWalletConnection, shouldReconnect])

  // reconnect wallet if previously connected
  useEffect(() => {
    if (!wallet.account && shouldReconnect && lastWalletConnection)
      wallet.connect(lastWalletConnection)
  }, [wallet.account, lastWalletConnection, shouldReconnect, wallet.connect])

  return {
    // ...rest,
    // provider,
    // active,
    // network,
    // chainId,
    // isMetaMask,
    // etherBalance: etherBalance && formatEther(etherBalance),
    // tokenBalance: tokenBalance && Number(tokenBalance),
    ...wallet,
    disconnect,
    connectorReadable: getReadableConnector(wallet.connector),
    accountShort: wallet.account && shortenAddress(wallet.account),
  }
}

function getReadableConnector(connector) {
  switch (connector) {
    case 'injected':
      return 'MetaMask'
    case 'walletconnect':
      return 'WalletConnect'
    case 'walletlink':
      return 'Coinbase Wallet'
    default:
      return 'Not Connected'
  }
}

export default useWallet
