import { extendTheme } from '@chakra-ui/react'
import { mode, createBreakpoints } from '@chakra-ui/theme-tools'
// import colors from 'token/assets/colors.json'
import colors from 'src/colors.json'

export const theme = extendTheme({
  colors,
  fonts: {
    heading: 'ui-rounded, "SF Pro Rounded", system-ui, san-serif',
    body: 'ui-rounded, "SF Pro Rounded", system-ui, san-serif',
  },
  styles: {
    global: (props) => ({
      body: {
        // color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode('white', 'gray.900')(props),
      },
    }),
  },
  layout: {
    navigatorWidth: 280,
    asideWidth: 280,
  },
  breakpoints: createBreakpoints({
    sm: "30em",
    md: "48em",
    lg: "62em",
    xl: "75em",
    "2xl": "96em",
  })
})
