import * as React from 'react'
import { routes } from '@redwoodjs/router'
import {
  Box,
  Flex,
  FlexProps,
  Stack,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react'
import SignInUser from 'src/components/SignInUser'
import { NavLinkButton, NavLinkButtonProps } from 'src/components/LinkButton'
import Icon, { IconProps } from 'src/components/Icon'
import Link from 'src/components/Link'
import Hoverable from 'src/components/Hoverable'
import Gnomies from './gnomies.svg'

const NavigatorButton = (props: NavLinkButtonProps) => {
  const bg = useColorModeValue('gray.200', 'gray.800')
  const bgActive = useColorModeValue('gray.300', 'gray.700')
  const bgHover = useColorModeValue('gray.100', 'gray.900')

  return (
    <Hoverable>
      <NavLinkButton
        justifyContent={{ base: 'center', lg: 'start' }}
        backgroundColor={{ base: 'transparent', lg: bg }}
        color={{ base: 'gray.500', lg: 'inherit' }}
        fontSize={{ base: 12, lg: 18 }}
        fontWeight={400}
        width={{ base: 16, lg: '100%' }}
        height={{ base: 16, lg: 12 }}
        px={2}
        borderRadius={12}
        iconSpacing={{ base: 0, lg: 3 }}
        flexDir={{ base: 'column', lg: 'row' }}
        _activeLink={{ bg: { base: 'transparent', lg: bgActive } }}
        _hover={{ bg: { base: 'transparent', lg: bgHover } }}
        sx={{
          '&.activeLink': {
            bg: { base: 'transparent', lg: bgActive },
          },
        }}
        {...props}
      />
    </Hoverable>
  )
}

const NavigatorIcon = (props: IconProps) => {
  return (
    <Icon
      prefix="fal"
      mb={{ base: 1, lg: 0 }}
      fontSize={{ base: 28, lg: 24 }}
      space={0}
      fixedWidth
      {...props}
    />
  )
}

export const Navigator = (props: FlexProps) => {
  const iconTavern = useColorModeValue('house-day', 'house-night')
  const showSignIn = useBreakpointValue({ base: false, lg: true })
  const hollowText = useBreakpointValue({ base: 'Hollow', lg: 'The Hollow' })
  const tavernText = useBreakpointValue({ base: 'Tavern', lg: 'Goldie Tavern' })
  const secretsText = useBreakpointValue({
    base: 'Secrets',
    lg: 'Book of Secrets',
  })

  return (
    <Flex justifyContent="space-between" flexDirection="column" {...props}>
      <Hoverable>
        <Link
          to={routes.passport()}
          display={{ base: 'none', lg: 'block' }}
          borderRadius={8}
        >
          <Box
            id="logo"
            title="gnomies"
            width="100%"
            as={Gnomies}
            px={8}
            py={2}
          />
        </Link>
      </Hoverable>
      <Stack
        mt={{ base: 0, lg: 4 }}
        mb="auto"
        align={{ base: 'center', lg: 'stretch' }}
        justify="center"
        direction={{ base: 'row', lg: 'column' }}
        spacing={2}
      >
        <NavigatorButton
          to={routes.passport()}
          leftIcon={<NavigatorIcon name="passport" color="blue.500" />}
          children="Passport"
        />
        <NavigatorButton
          to={routes.hollow()}
          leftIcon={<NavigatorIcon name="globe-stand" color="green.500" />}
          children={hollowText}
        />
        <NavigatorButton
          to={routes.tavern()}
          leftIcon={<NavigatorIcon name={iconTavern} color="yellow.500" />}
          children={tavernText}
        />
        <NavigatorButton
          to={routes.secrets()}
          leftIcon={<NavigatorIcon name="book-sparkles" color="purple.500" />}
          children={secretsText}
        />
      </Stack>
      {showSignIn && <SignInUser />}
    </Flex>
  )
}
