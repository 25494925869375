import * as React from 'react'
import {
  Button as ChakraButton,
  ButtonProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { Link, NavLink } from '../Link/Link'

export type LinkButtonProps = Omit<typeof Link, 'isActive'> & ButtonProps
export type NavLinkButtonProps = Omit<typeof NavLink, 'isActive'> & ButtonProps

export const Button = (props: LinkButtonProps) => {
  const bg = useColorModeValue('white', 'gray.900')

  return (
    <ChakraButton bg={bg} {...props}>
      {props.children}
    </ChakraButton>
  )
}

export const LinkButton = (props: LinkButtonProps) => {
  const bg = useColorModeValue('white', 'gray.900')

  return (
    <ChakraButton as={Link} bg={bg} {...props}>
      {props.children}
    </ChakraButton>
  )
}

export const NavLinkButton = (props: NavLinkButtonProps) => {
  // const { pathname } = useLocation()
  // const isActive = pathname.includes(props.to as string)
  const onClick = props.disabled
    ? (e) => {
        e.preventDefault()
      }
    : () => {}

  return (
    <ChakraButton as={NavLink} onClick={onClick} {...props}>
      {props.children}
    </ChakraButton>
  )
}
