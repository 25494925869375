import { useState, useEffect } from 'react'
import { useParams } from '@redwoodjs/router'
import useWallet from 'src/hooks/useWallet'
import { fetchAllTokens, uniqueImages } from 'src/api/opensea'

function useTokens() {
  const { address } = useParams() // allows route for specific wallet
  const { account } = useWallet()
  const [tokens, setTokens] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const walletId = address || account

  useEffect(async () => {
    if (walletId) {
      setLoading(true)
      await fetchAllTokens(walletId)
        .then(uniqueImages)
        .then(setTokens)
        .catch(setError)
      setLoading(false)
    }
  }, [walletId])

  return { tokens, loading, error }
}

export default useTokens
