import * as React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  IconPrefix as FontAwesomeIconPrefix,
  IconName as FontAwesomeIconName,
  findIconDefinition,
} from '@fortawesome/fontawesome-svg-core'
import {
  iconsThin,
  iconsLight,
  iconsRegular,
  iconsSolid,
  iconsDuotone,
  iconsBrand,
} from 'src/icons'

const getIcon = (iconName?: IconName, prefix?: IconPrefix) => {
  if (!iconName) return findIconDefinition({ prefix: 'fas', iconName: 'star' })
  if (prefix) return findIconDefinition({ prefix, iconName })
  const iconId = iconName.split('-').join('')
  const flattenId = (id: string) => id.toLowerCase().replace('fa', '')

  const isDuotone = iconsDuotone.map(flattenId).includes(iconId)
  if (isDuotone) return findIconDefinition({ prefix: 'fad', iconName })

  const isSolid = iconsSolid.map(flattenId).includes(iconId)
  if (isSolid) return findIconDefinition({ prefix: 'fas', iconName })

  const isRegular = iconsRegular.map(flattenId).includes(iconId)
  if (isRegular) return findIconDefinition({ prefix: 'far', iconName })

  const isLight = iconsLight.map(flattenId).includes(iconId)
  if (isLight) return findIconDefinition({ prefix: 'fal', iconName })

  const isThin = iconsThin.map(flattenId).includes(iconId)
  if (isThin) return findIconDefinition({ prefix: 'fat', iconName })

  const isBrand = iconsBrand.map(flattenId).includes(iconId)
  if (isBrand) return findIconDefinition({ prefix: 'fab', iconName })

  return findIconDefinition({ prefix: 'fas', iconName })
}

interface FontAwesomeIconProps {
  name: IconName
  prefix?: IconPrefix
  fixedWidth?: boolean
}

export type IconName = FontAwesomeIconName
export type IconPrefix = FontAwesomeIconPrefix
export type IconProps = FontAwesomeIconProps & BoxProps

const Icon = (props: IconProps) => {
  const { name, prefix, fixedWidth, ...boxProps } = props
  const icon = getIcon(name as IconName, prefix as IconPrefix)

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...boxProps}
    >
      <FontAwesomeIcon icon={icon} fixedWidth={fixedWidth} size={props.size} />
    </Box>
  )
}

export default Icon
