import querystring from 'querystring'

const API = 'https://api.opensea.io/api/v1'
const FETCH_LIMIT = 50

export async function fetchTokens(owner, offset = 0, limit = FETCH_LIMIT) {
  const opts = { owner, offset, limit }
  const url = `${API}/assets/?${querystring.stringify(opts)}`
  console.log(url)
  return fetch(url)
    .then((res) => res.json())
    .then((json) => json?.assets || [])
}

export async function fetchAllTokens(owner, offset = 0) {
  const tokens = await fetchTokens(owner, offset)
  if (tokens.length < FETCH_LIMIT) return tokens
  if (tokens.length === FETCH_LIMIT) {
    const rest = await fetchAllTokens(owner, offset + 50)
    return [...tokens, ...rest]
  } else {
    throw Error('Something went wrong while requesting tokens from OpenSea')
  }
}

export function uniqueImages(tokens = []) {
  const images = tokens.map((token) => token.image_url)
  const imagesUnique = new Set(images)
  const findToken = (image) => tokens.find((t) => t.image_url === image)
  return Array.from(imagesUnique).map(findToken)
}
