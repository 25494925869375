// import { ChainId } from '@usedapp/core'

// const chains = [
//   ChainId.Mainnet,
//   ChainId.Ropsten,
//   ChainId.Rinkeby,
//   ChainId.Goerli,
//   ChainId.Kovan,
// ]

// export const config = {
//   readOnlyChain: 1337,
//   readOnlyUrls: {
//     [1337]: 'HTTP://127.0.0.1:7545',
//   },
//   supportedChains: [...chains, 1337],
//   multicallAddresses: {
//     [1337]: '0x856eA404A4E26D988458bba288c1bB1C86253F16',
//   },
// }
export const connectors = {
  walletconnect: {
    rpcUrl: `https://mainnet.infura.io/v3/${process.env.INFURA_ID}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
  },
  walletlink: {
    url: `https://mainnet.infura.io/v3/${process.env.INFURA_ID}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
  },
}

// export const networkId = (id) => {
//   switch (id) {
//     case 1337:
//       return 5777
//     default:
//       return id
//   }
// }
