import {
  Grid,
  GridProps,
  Box,
  useTheme,
  useColorModeValue,
} from '@chakra-ui/react'
import { Navigator } from 'src/components/Navigator'
import MetaTags from 'src/components/MetaTags'

const AppLayout = ({ children, ...props }: GridProps) => {
  React.useEffect(() => window.scrollTo(0, 0), [])
  const { layout } = useTheme()
  const borderColor = useColorModeValue('gray.200', 'gray.800')
  const bodyColor = useColorModeValue('white', 'gray.900')
  const bgNavigatorDesktop = useColorModeValue('gray.200', 'gray.800')
  const bgNavigatorMobile = useColorModeValue('white', 'gray.900')

  return (
    <>
      <MetaTags />
      <Grid {...props}>
        <Grid
          minW="100vw"
          height="100%"
          placeSelf="start"
          templateAreas={{
            base: `
            "body"
            "navigator"
          `,
            lg: `
            "navigator body"
          `,
          }}
          templateColumns={{
            base: '1fr',
            lg: `${layout.navigatorWidth}px 1fr`,
          }}
          templateRows={{ base: '1fr auto', lg: '1fr' }}
        >
          <Navigator
            gridArea="navigator"
            position={{ base: 'fixed', lg: 'fixed' }}
            pt={3}
            px={4}
            pb={4}
            right={{ base: 0, lg: 'auto' }}
            top={{ base: 'auto', lg: 0 }}
            left={0}
            bottom={0}
            zIndex={1}
            width={{ base: '100%', lg: `${layout.navigatorWidth}px` }}
            height={{ base: 'auto', lg: '100vh' }}
            borderTopColor={borderColor}
            borderTopWidth={{ base: 2, lg: 0 }}
            gridGap={{ base: 0, lg: 4 }}
            bg={{ base: bgNavigatorMobile, lg: bgNavigatorDesktop }}
          />
          <Box
            gridArea="body"
            pb={{ base: 'calc(94px + env(safe-area-inset-bottom))', lg: 0 }}
            bg={bodyColor}
            children={children}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default AppLayout
