import { MetaTags as RedwoodMetaTags } from '@redwoodjs/web'
import shareCard from 'src/components/MetaTags/gnomies-banner.png'

const MetaTags = () => {
  return (
    <RedwoodMetaTags
      title="gnomies"
      description="digital identity products"
      author="@gnomies"
      ogContentUrl={shareCard}
      ogWidth="960"
      ogHeight="480"
    />
  )
}

export default MetaTags
