export const gnomiesAuth = {
  type: 'custom',
  logIn: {
    twitter: () => window.location = '/.netlify/functions/twitter-auth'
  },
  logout: {
    // {
    // TODO: implement server side auth logout
    // window.location = '/.netlify/functions/twitter-signout'
    // },
    twitter: async () => localStorage.removeItem('gnomies.token'),
    everything: async () => localStorage.removeItem('gnomies.token')
  },
  getToken: async () => localStorage.getItem('gnomies.token'),
  getUserMetadata: async () => localStorage.getItem('gnomies.token'),
  restoreAuthState: () => localStorage.getItem('gnomies.token'),
}
