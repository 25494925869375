import { library } from '@fortawesome/fontawesome-svg-core'
import * as thin from './thin'
import * as light from './light'
import * as regular from './regular'
import * as solid from './solid'
import * as duotone from './duotone'
import * as brand from './brand'

export const iconsThin = Object.keys(thin)
export const iconsLight = Object.keys(light)
export const iconsRegular = Object.keys(regular)
export const iconsSolid = Object.keys(solid)
export const iconsDuotone = Object.keys(duotone)
export const iconsBrand = Object.keys(brand)

export const initializeIconLibrary = () => {
  iconsThin.forEach((icon) => library.add(thin[icon]))
  iconsLight.forEach((icon) => library.add(light[icon]))
  iconsRegular.forEach((icon) => library.add(regular[icon]))
  iconsSolid.forEach((icon) => library.add(solid[icon]))
  iconsDuotone.forEach((icon) => library.add(duotone[icon]))
  iconsBrand.forEach((icon) => library.add(brand[icon]))
}
