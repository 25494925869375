import { useAuth } from '@redwoodjs/auth'
import { Text } from '@chakra-ui/react'
import { routes, navigate } from '@redwoodjs/router'
import { NavLinkButton } from 'src/components/LinkButton'
import AvatarButton, { AvatarButtonLoading } from 'src/components/AvatarButton'
import useWallet from 'src/hooks/useWallet'
import Icon from 'src/components/Icon'
import Hoverable from 'src/components/Hoverable'

const faces = [
  'face-angry',
  'face-dizzy',
  'face-explode',
  'face-flushed',
  'face-frown',
  'face-frown-open',
  'face-grimace',
  'face-grin',
  'face-grin-beam',
  'face-grin-beam-sweat',
  'face-grin-hearts',
  'face-grin-squint',
  'face-grin-squint-tears',
  'face-grin-stars',
  'face-grin-tears',
  'face-grin-tongue',
  'face-grin-tongue-squint',
  'face-grin-tongue-wink',
  'face-grin-wide',
  'face-grin-wink',
  'face-kiss',
  'face-kiss-beam',
  'face-kiss-wink-heart',
  'face-laugh',
  'face-laugh-beam',
  'face-laugh-squint',
  'face-laugh-wink',
  'face-meh',
  'face-meh-blank',
  'face-rolling-eyes',
  'face-sad-cry',
  'face-sad-tear',
  'face-smile-beam',
  'face-smile-wink',
  'face-surprise',
  'face-tired',
]

const randomFace = () => faces[Math.floor(Math.random() * faces.length)]

const SignInUser = () => {
  const [face, setFace] = React.useState('face-smile')

  return (
    <Hoverable>
      <NavLinkButton
        to={routes.profile()}
        justifyContent="start"
        bgGradient="linear(33deg,green.500,blue.500,purple.500,orange.500,red.500)"
        color="gray.100"
        fontSize={22}
        textAlign="center"
        width="100%"
        height="auto"
        letterSpacing="0.02em"
        fontWeight={600}
        px={2}
        py={3}
        borderRadius={32}
        iconSpacing={2}
        leftIcon={
          <Icon
            name={face}
            prefix="far"
            color="gray.100"
            fontSize={28}
            fixedWidth
          />
        }
        onMouseEnter={() => setFace(randomFace())}
        onMouseLeave={() => setFace('face-smile')}
        _active={{
          bgGradient:
            'linear(33deg,green.400,blue.400,purple.400,orange.400,red.400)',
        }}
        _hover={{
          bgGradient:
            'linear(33deg,green.500,blue.500,purple.500,orange.500,red.500)',
        }}
      >
        Sign in
      </NavLinkButton>
    </Hoverable>
  )
}

function UserDetails({ currentUser }) {
  return (
    <AvatarButton
      onClick={() => navigate(routes.profile())}
      name={currentUser.twitter.username}
      src={currentUser.twitter.profile_image_url.replace('normal', '400x400')}
      title={currentUser.twitter.name}
      subtitle={`@${currentUser.twitter.username}`}
      detail={<Icon name="ellipsis" prefix="far" />}
    />
  )
}

function WalletDetails({ accountShort }) {
  return (
    <AvatarButton
      onClick={() => navigate(routes.profile())}
      title={accountShort}
      subtitle={<Text>Sign in with Twitter</Text>}
      detail={<Icon name="ellipsis" prefix="far" />}
    />
  )
}

function DetailsOrSignIn() {
  const { currentUser, loading } = useAuth()
  const { accountShort, etherBalance } = useWallet()

  if (loading)
    return <AvatarButtonLoading onClick={() => navigate(routes.profile())} />
  if (currentUser) return <UserDetails currentUser={currentUser} />
  if (accountShort)
    return (
      <WalletDetails accountShort={accountShort} etherBalance={etherBalance} />
    )

  return <SignInUser />
}

export default DetailsOrSignIn
