import createPersistedState from 'use-persisted-state'
const useReconnectState = createPersistedState('reconnect-wallet')
const useLastWalletConnectionState = createPersistedState(
  'last-wallet-connection'
)

export const useReconnectWallet = (init = false) => {
  const [shouldReconnect, setShouldReconnect] = useReconnectState(init)

  return {
    shouldReconnect,
    setShouldReconnect,
    toggleShouldReconnect: () => setShouldReconnect(!shouldReconnect),
  }
}

// lastWalletConnection can be null and connected which implies metamask or other injected wallet
export const useLastWalletConnection = (init = null) => {
  const [
    lastWalletConnection,
    setLastWalletConnection,
  ] = useLastWalletConnectionState(init)

  return {
    lastWalletConnection,
    setLastWalletConnection,
  }
}

const usePreferences = () => {
  const reconnectWallet = useReconnectWallet()
  const lastWalletConnection = useLastWalletConnection()
  return { ...reconnectWallet, ...lastWalletConnection }
}

export default usePreferences
