import { Link as RouterLink, NavLink as RouterNavLink } from '@redwoodjs/router'
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react'

export type LinkProps = typeof RouterLink & ChakraLinkProps
export type NavLinkProps = typeof RouterNavLink & ChakraLinkProps

export const Link = (props: LinkProps) => (
  <ChakraLink
    as={RouterLink}
    _hover={{
      textDecoration: 'none',
    }}
    {...props}
  />
)

export const NavLink = (props: NavLinkProps) => {
  return (
    <ChakraLink
      as={RouterNavLink}
      activeClassName="activeLink"
      _hover={{
        textDecoration: 'none',
      }}
      {...props}
    />
  )
}

export default Link
