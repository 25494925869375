// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/GardenPage/GardenPage.js'         -> GardenPage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Set } from '@redwoodjs/router'
import AppLayout from 'src/layouts/AppLayout'
import { PassportContextProvider } from 'src/contexts/PassportContext'

const Routes = () => {
  return (
    <Router>
      <Set wrap={[AppLayout]}>
        <Route path="/" page={HomePage} name="home" />
        <Set wrap={[PassportContextProvider]}>
          <Route path="/passport" page={PassportPage} name="passport" />
          <Route path="/passport/tokens" page={PassportTokensPage} name="passportTokens" />
          <Route path="/passport/wallet/{address:String}" page={PassportPage} name="passportWallet" />
        </Set>
        <Route path="/hollow" page={HollowPage} name="hollow" />
        <Route path="/tavern" page={TavernPage} name="tavern" />
        <Route path="/secrets" page={SecretsPage} name="secrets" />
        <Route path="/profile" page={ProfilePage} name="profile" />
        <Route path="/auth" page={AuthPage} name="auth" />
        <Route path="/gnomie/{id:Int}" page={GnomiePage} name="gnomie" />
        <Route notfound page={NotFoundPage} />
      </Set>
    </Router>
  )
}

export default Routes
