import { AuthProvider } from '@redwoodjs/auth'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import { UseWalletProvider } from 'use-wallet'
import { connectors } from 'src/dapp'
import { ColorModeScript, ChakraProvider } from '@chakra-ui/react'
import { theme } from 'src/theme'
import { gnomiesAuth } from 'src/auth/gnomies-auth'
import { initializeIconLibrary } from 'src/icons'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

initializeIconLibrary()

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider>
      <ChakraProvider theme={theme}>
        <ColorModeScript />
        <UseWalletProvider chainId={1} connectors={connectors}>
          <AuthProvider client={gnomiesAuth} type="custom">
            <RedwoodApolloProvider>
              <Routes />
            </RedwoodApolloProvider>
          </AuthProvider>
        </UseWalletProvider>
      </ChakraProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
)

export default App
