import {
  Grid,
  Box,
  Button,
  Avatar,
  Circle,
  Text,
  Skeleton,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react'
import Hoverable from 'src/components/Hoverable'

function AvatarButton({ src, name, title, subtitle, detail, ...props }) {
  return (
    <Hoverable>
      <Grid
        as={Button}
        templateColumns={detail ? 'auto 1fr auto' : 'auto 1fr'}
        templateRows="0 auto auto 0"
        templateAreas={
          detail
            ? `
          "avatar . detail"
          "avatar title detail"
          "avatar subtitle detail"
          "avatar . detail"
        `
            : `
        "avatar ."
          "avatar title"
          "avatar subtitle"
          "avatar ."
        `
        }
        columnGap={3}
        alignItems="center"
        width="100%"
        height="auto"
        textAlign="left"
        borderRadius={32}
        bg="transparent"
        p={2}
        pr={4}
        _hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
        {...props}
      >
        <Avatar
          src={src}
          name={name}
          bg={useColorModeValue('gray.300', 'gray.600')}
          width="40px"
          height="40px"
          gridArea="avatar"
        />

        <Text
          gridArea="title"
          fontSize={16}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          fontWeight={600}
          lineHeight={1.4}
        >
          {title}
        </Text>

        <Text
          gridArea="subtitle"
          color="gray.500"
          fontSize={14}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          lineHeight={1.4}
        >
          {subtitle}
        </Text>

        {detail && <Box gridArea="detail">{detail}</Box>}
      </Grid>
    </Hoverable>
  )
}

export const AvatarButtonLoading = (props) => {
  return (
    <Grid
      as={Button}
      templateColumns={'auto 1fr'}
      templateRows="1fr auto auto 1fr"
      templateAreas={`
        "avatar ."
        "avatar title"
        "avatar subtitle"
        "avatar ."
      `}
      columnGap={3}
      alignItems="center"
      width="100%"
      height="auto"
      borderRadius={32}
      bg={useColorModeValue('gray.100', 'gray.700')}
      p={2}
      pr={4}
      rowGap={1}
      _hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
      _focus={{ bg: useColorModeValue('gray.100', 'gray.700') }}
      {...props}
    >
      <Circle
        bg={useColorModeValue('gray.200', 'gray.600')}
        width="40px"
        height="40px"
        gridArea="avatar"
      >
        <Spinner color={useColorModeValue('gray.100', 'gray.700')} />
      </Circle>

      <Skeleton
        gridArea="title"
        height={4}
        width={32}
        startColor={useColorModeValue('gray.200', 'gray.600')}
        endColor={useColorModeValue('gray.300', 'gray.700')}
      />
      <Skeleton
        gridArea="subtitle"
        height={3}
        width={24}
        startColor={useColorModeValue('gray.200', 'gray.600')}
        endColor={useColorModeValue('gray.300', 'gray.700')}
      />
    </Grid>
  )
}

export default AvatarButton
