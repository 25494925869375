import { createContext, useMemo, useReducer, useEffect } from 'react'
import useTokens from 'src/hooks/useTokens'

function reducer(state, action) {
  switch (action.type) {
    case 'setActiveToken':
      return {
        ...state,
        activeToken: action.payload,
      }
    case 'setTokens':
      return {
        ...state,
        tokens: action.payload,
      }
    default:
      throw new Error()
  }
}

const initialState = {
  activeToken: null,
  tokens: [],
}

export const PassportContext = createContext([{}, () => {}])

export const PassportContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { tokens = [] } = useTokens()

  useEffect(() => dispatch({ type: 'setTokens', payload: tokens }), [tokens])

  const value = useMemo(() => {
    const activeIndex = tokens
      .map((token) => token.id)
      .indexOf(state.activeToken?.id)

    function setActiveToken(token) {
      dispatch({ type: 'setActiveToken', payload: token })
    }

    function setNextToken() {
      if (tokens.length < 2) return () => {}
      if (activeIndex === tokens.length - 1) return setActiveToken(tokens[0])
      return setActiveToken(tokens[activeIndex + 1])
    }

    return {
      ...state,
      activeIndex,
      setActiveToken,
      setNextToken,
    }
  }, [state, dispatch])

  return (
    <PassportContext.Provider value={value}>
      {props.children}
    </PassportContext.Provider>
  )
}
